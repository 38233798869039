import { UiKit } from '@datavant/uikit';
import React from 'react';
import { RecordHubPageFooter } from '../SmartRequest/RecordHubPageContainer';
import Image from 'next/image';
import new_logo from '../../public/new-login-logo.png';
import { useOktaAuth } from '@okta/okta-react';

const LoginButton = (): JSX.Element => {
    const { oktaAuth, authState } = useOktaAuth();
    const login = async (): Promise<void> => {
        // clear session storage on login
        sessionStorage.clear();
        return oktaAuth.signInWithRedirect({
            redirectUri: window.location.origin + '/cb',
            originalUri: window.location.origin,
        });
    };
    if (!authState) {
        return <UiKit.SpinnerWithText text={'Loading...'} />;
    } else if (authState.isAuthenticated) {
        return <UiKit.SpinnerWithText text={'Redirecting...'} />;
    }
    return <UiKit.Button.Primary text="Sign into Smart Request" onClick={login} />;
};

const LegacyOktaLogin = () => {
    const modalBody = (
        <UiKit.InfoBox>
            <UiKit.Chakra.HStack justify={'center'}>
                <Image src={new_logo} alt="Smart Request" priority width={'270'} height={'91'} />
            </UiKit.Chakra.HStack>
            <hr style={{ borderTop: '1px solid black', marginLeft: '-10%', marginRight: '-10%' }} />

            <UiKit.Chakra.VStack w={'100%'} justify={'center'}>
                <div
                    style={{
                        color: UiKit.consistency.brandColors.cancelButton.font,
                        fontFamily: 'DM Sans',
                        fontWeight: 400,
                        fontSize: '28px',
                        lineHeight: '42px',
                    }}
                >
                    Smart Request
                </div>
                <br />
                <LoginButton />
            </UiKit.Chakra.VStack>
            <div style={{ marginLeft: '68px' }}>
                <UiKit.Chakra.VStack spacing={UiKit.consistency.buffer} align={'left'} width={'270px'}>
                    <UiKit.Text.Clickable
                        text="Forgot password?"
                        onClick={() => {
                            location.href = 'https://cioxapps.okta.com/signin/forgot-password';
                        }}
                        underline={true}
                    />
                    <UiKit.Text.BodyRegular>
                        <span style={{ fontWeight: 700 }}>New to Smart Request? </span> Click below to sign up
                        for digital records retrieval from Datavant (formerly Ciox Health) Partner Sites.
                    </UiKit.Text.BodyRegular>
                    <UiKit.Text.Clickable
                        text="Click here to sign up"
                        onClick={() => {
                            window.open('/open/self-registration', '_self');
                        }}
                        underline={true}
                    />
                    <RecordHubPageFooter dontShowModals={true} />
                </UiKit.Chakra.VStack>
            </div>
        </UiKit.InfoBox>
    );

    const newBrandLogin = (
        <UiKit.Chakra.Modal
            autoFocus={false}
            onClose={() => undefined}
            isOpen={true}
            scrollBehavior={'outside'}
            isCentered={true}
            // size={'md'}
            closeOnOverlayClick={false}
        >
            <UiKit.Chakra.ModalOverlay bgColor={UiKit.consistency.gray70} />
            <UiKit.Chakra.ModalContent style={{ background: 'none' }}>{modalBody}</UiKit.Chakra.ModalContent>
        </UiKit.Chakra.Modal>
    );

    return newBrandLogin;
};

export default LegacyOktaLogin;
